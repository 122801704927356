import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import BlockKeyValue from 'components/ui/BlockKeyValue/BlockKeyValue';
import Button from 'components/ui/Button/Button';
import styles from './SectionContact.module.sass';

interface ISectionContactProps {
  name?: string;
  installationAddress?: string;
  billingAddress?: string;
  distance?: string;
  email?: string;
  companyName?: string;
  billingCompanyName?: string;
  phone?: string;
  mobile?: string;
  assignedTo?: string;
  clientRequestId?: string;
}

const SectionContact: React.FC<ISectionContactProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Rectangle title={t('installerFlow:orderDetails:contact')}>
      <>
        {[
          {
            title: t('installerFlow:orderDetails:name'),
            value: props.name,
          },
          {
            title: t('installerFlow:orderDetails:installationAddress'),
            value: props.installationAddress,
          },
          { title: t('installerFlow:orderDetails:companyName'), value: props.companyName },
          { title: t('installerFlow:orderDetails:distance'), value: props.distance },
          { title: t('installerFlow:orderDetails:billingAddress'), value: props.billingAddress },
          { title: t('installerFlow:orderDetails:companyName'), value: props.billingCompanyName },

          { title: t('installerFlow:orderDetails:email'), value: props.email },
          { title: t('installerFlow:orderDetails:phone'), value: props.phone },
          { title: t('installerFlow:orderDetails:mobile'), value: props.mobile },
          { title: t('installerFlow:orderDetails:assignedTo'), value: props.assignedTo },
        ].map((item: any, index: number) => (
          <BlockKeyValue key={index} label={item.title} value={item.value} />
        ))}

        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            onClick={() => navigate(`/installer/tender/${props.clientRequestId}`)}
            size="small"
          >
            {t('installerFlow:orderDetails:openClientRequest')}
          </Button>
        </div>
      </>
    </Rectangle>
  );
};

export default SectionContact;
