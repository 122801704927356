import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import { ClientRequestState, IClientRequest } from 'store/client-request/client-request.types';
import { OrderState } from 'store/orders/orders.types';
import { IOffer, OfferEventType, OfferState } from 'store/offer/offer.types';
import { offerTransition } from 'store/offer/offer.actions';
import { userDataSelector } from 'store/auth/auth.selectors';
import { getCurrentOffer } from 'utils/offerHelpers';
import Styles from './ActionWidget.module.sass';
import styles from './TenderDetailsPage.module.sass';

const LOCIZE_KEY = 'installerFlow';
const SendOrRejectOffer = ({ clientRequest }: { clientRequest: IClientRequest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isQuestionnaireSkipped = clientRequest?.questionnaireSkipped;

  if (clientRequest.archived) return null;
  const currentOffer = getCurrentOffer(clientRequest.offers || []);
  if (
    !(
      !currentOffer ||
      currentOffer.state == OfferState.TenderCancelled ||
      currentOffer.state === OfferState.TenderRejected
    )
  )
    return null;
  const submitText = isQuestionnaireSkipped
    ? t('installerFlow:tenderDetails:buttonHomeCheckOffer')
    : t('installerFlow:tenderDetails:buttonSendOffer');

  const BUTTONS = [
    {
      color: '',
      title: t('installerFlow:tenderDetails:buttonRejectRequest'),
      danger: true,
      description: t('installerFlow:tenderDetails:declineText'),
      onClick: () => createRejectedOffer(),
    },
    {
      color: 'green',
      title: submitText,
      description: t('installerFlow:tenderDetails:onTermsText'),
      onClick: () => navigate(`/installer/tender/${clientRequest.id}/offer-type`),
    },
  ];

  const rejectCreatedOffer = (offer: IOffer) => {
    dispatch(
      offerTransition(OfferEventType.INSTALLER_REJECTED, offer, () =>
        navigate('/installer/dashboard'),
      ),
    );
  };
  const createRejectedOffer = () => {
    if (!clientRequest) return;
    const createDto = {
      clientRequestId: clientRequest.id,
    };
    dispatch(
      offerTransition(OfferEventType.INITIATED, createDto, (createdOffer: IOffer) =>
        rejectCreatedOffer(createdOffer),
      ),
    );
  };
  return (
    <Row style={{ paddingTop: '20px' }} gutter={[16, 16]}>
      {BUTTONS.map((button: any, index: number) => (
        <Col className={styles.buttonsCol} span={12} key={`action-button-${index}`}>
          <Button
            type="primary"
            block={true}
            color={button.color}
            danger={button.danger}
            onClick={button.onClick}
          >
            {button.title}
          </Button>

          <small className={styles.small}>{button.description}</small>
        </Col>
      ))}
    </Row>
  );
};
const ActionWidget = ({ clientRequest }: { clientRequest: IClientRequest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const unconfirmedOrder = clientRequest.orders?.find(
    (order) => order.state === OrderState.Created,
  );
  const userData = useSelector(userDataSelector);
  const requestOffers = clientRequest.offers?.filter(
    (_offer) => _offer.installerUserId === userData.id,
  );

  const isNotAssignedRequest = !unconfirmedOrder && (!requestOffers || !requestOffers.length);
  const handleContinueClick = () => {
    if (unconfirmedOrder) {
      navigate(`/order/${unconfirmedOrder.id}/accept`);
      return;
    }
    const currentOffer = requestOffers && getCurrentOffer(requestOffers);
    if (!currentOffer) return;
    if (currentOffer.state === OfferState.TenderCreated) {
      navigate(`/installer/order/${currentOffer.id}/make-offer`);
      return;
    }
    navigate(`/installer/order/${currentOffer.id}/details`);
  };
  const getButtonContent = () => {
    if (clientRequest.state === ClientRequestState.Done)
      return t(`${LOCIZE_KEY}:actionWidget:done`);
    if (unconfirmedOrder) return t(`${LOCIZE_KEY}:actionWidget:acceptJob`);
    return t(`${LOCIZE_KEY}:actionWidget:continue`);
  };
  return (
    <Rectangle contentClassName={Styles.Container} title={t(`${LOCIZE_KEY}:actionWidget:title`)}>
      <Flex vertical={true} gap={8} justify="space-between" flex={1}>
        {isNotAssignedRequest ? (
          <SendOrRejectOffer clientRequest={clientRequest} />
        ) : (
          <>
            <Typography.Text>{t(`${LOCIZE_KEY}:actionWidget:text`)}</Typography.Text>

            <Button
              type={'primary'}
              className={Styles.FullWidthButton}
              onClick={handleContinueClick}
              disabled={clientRequest.state === ClientRequestState.Done}
            >
              {getButtonContent()}
            </Button>
          </>
        )}
      </Flex>
    </Rectangle>
  );
};
export default ActionWidget;
