import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import rhcImage from 'assets/images/home_check_types/rhc.jpeg';
import hcImage from 'assets/images/home_check_types/hc.jpeg';
import nohcImage from 'assets/images/home_check_types/nohc.jpeg';
import Loader from 'components/ui/Loader/Loader';
import { acceptOrder, getOrderById } from 'store/orders/orders.actions';
import { currentOrderSelector, getOrderByIdStateSelector } from 'store/orders/orders.selectors';
import { RequestState } from 'store/common.types';
import { OrderConfirmationType } from 'store/orders/orders.types';
import styles from './Accepted.module.scss';
import HomeCheckTypeCard, { ExtraInfoType } from './HomeCheckTypeCard';
import HomeCheckConfirmSection from './HomeCheckConfirmSection';
import HomeCheckHeaderSection from './HomeCheckHeaderSection';
import RemoteHomeCheckModal from './RemoteHomeCheckModal';
import HomeCheckTypes from './HomeCheckTypes.enum';

type HomeCheckOption = {
  idx: number;
  backgroundImg: string;
  topBadge?: { color: string; text: string };
  title: string;
  description: string;
  extraInfo?: { type: ExtraInfoType; message: string };
};

const LOCIZE_KEY = 'installerFlow:order:accept';

export const OrderAccepted: React.FC = () => {
  const [selectedHomeCheckOption, setSelectedHomeCheckOption] = useState<number>(
    HomeCheckTypes.Remote,
  );
  const [shouldShowRemoteHomeCheckModal, setShowRemoteHomeCheckModal] = useState<boolean>(false);
  const [offerId, setOfferId] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const { id: orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const order = useSelector(currentOrderSelector);
  const orderState = useSelector(getOrderByIdStateSelector);

  useEffect(() => {
    if (orderId) dispatch(getOrderById(orderId));
  }, [dispatch, orderId]);

  if (!orderId) return null;
  if (orderState === RequestState.Loading) return <Loader />;

  const shouldShowPaymentAlerts =
    Number(order?.clientRequest?.pool?.installerRemoteHomeCheckFee) > 0;

  const homeCheckOptions: Array<HomeCheckOption> = [
    {
      idx: HomeCheckTypes.Remote,
      topBadge: { color: 'green', text: t('common:recommended') },
      backgroundImg: rhcImage,
      title: t(`${LOCIZE_KEY}:rhc:cardTitle`),
      description: t(`${LOCIZE_KEY}:rhc:cardDescription`),
      ...(shouldShowPaymentAlerts && {
        extraInfo: {
          type: ExtraInfoType.Info,
          message: t(`${LOCIZE_KEY}:rhc:cardExtraInfo`, {
            amount: order?.clientRequest?.pool?.installerRemoteHomeCheckFee,
            currency: order?.clientRequest?.pool?.country?.currencySymbol,
          }),
        },
      }),
    },
    {
      idx: HomeCheckTypes.Onsite,
      backgroundImg: hcImage,
      title: t(`${LOCIZE_KEY}:hc:cardTitle`),
      description: t(`${LOCIZE_KEY}:hc:cardDescription`),
      extraInfo: {
        type: ExtraInfoType.Warning,
        message: t(`${LOCIZE_KEY}:hc:cardExtraInfo`),
      },
    },
    {
      idx: HomeCheckTypes.None,
      backgroundImg: nohcImage,
      title: t(`${LOCIZE_KEY}:nohc:cardTitle`),
      description: t(`${LOCIZE_KEY}:nohc:cardDescription`),
    },
  ];

  const handleConfirm = () => {
    switch (selectedHomeCheckOption) {
      case HomeCheckTypes.Remote:
        setLoading(true);
        dispatch(
          acceptOrder(
            orderId,
            undefined,
            OrderConfirmationType.ConfirmedRemote,
            (offerId: string) => {
              setLoading(false);
              setOfferId(offerId);
              setShowRemoteHomeCheckModal(true);
            },
          ),
        );
        break;
      case HomeCheckTypes.Onsite:
        navigate(`/order/${orderId}/accept/onsite-home-check`);
        break;
      case HomeCheckTypes.None:
        navigate(`/order/${orderId}/accept/no-home-check`);
        break;

      default:
        return;
    }
  };

  const handleScheduleNow = () => {
    if (!offerId) return;
    setShowRemoteHomeCheckModal(false);
    navigate(`/installer/order/${offerId}/details`);
  };

  const handleScheduleLater = () => {
    setShowRemoteHomeCheckModal(false);
    navigate('/');
  };

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <HomeCheckHeaderSection />
      </section>
      <section className={styles.optionsSection}>
        <Row gutter={16}>
          {homeCheckOptions.map((option) => (
            <Col className={styles.col} span={8} key={option.idx}>
              <HomeCheckTypeCard
                isSelected={option.idx === selectedHomeCheckOption}
                onSelect={() => setSelectedHomeCheckOption(option.idx)}
                {...option}
              />
            </Col>
          ))}
        </Row>
      </section>
      <section className={styles.confirmSection}>
        <HomeCheckConfirmSection
          isLoading={isLoading}
          selectedHomeCheckOption={selectedHomeCheckOption}
          shouldShowPaymentAlert={shouldShowPaymentAlerts}
          handleConfirm={handleConfirm}
        />
      </section>
      <RemoteHomeCheckModal
        isOpen={shouldShowRemoteHomeCheckModal}
        locizeKey={LOCIZE_KEY}
        handleScheduleLater={handleScheduleLater}
        handleScheduleNow={handleScheduleNow}
      />
    </div>
  );
};
