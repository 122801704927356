import React from 'react';
import clsx from 'clsx';
import { Alert, Badge, Card, Flex, Typography } from 'antd';

import styles from './Accepted.module.scss';

export enum ExtraInfoType {
  Warning = 'warning',
  Info = 'info',
}

type HomeCheckTypeCardProps = {
  backgroundImg: string;
  topBadge?: { color: string; text: string };
  title: string;
  description: string;
  extraInfo?: { type: ExtraInfoType; message: string };
  isSelected: boolean;
  onSelect: () => void;
};

const HomeCheckTypeCard = (props: HomeCheckTypeCardProps) => {
  return (
    <Badge.Ribbon
      className={clsx(styles.badge, { [styles.visible]: props.topBadge })}
      {...(props.topBadge ? { color: props.topBadge.color, text: props.topBadge.text } : {})}
    >
      <Card
        className={clsx(styles.card, { [styles.selected]: props.isSelected })}
        hoverable
        cover={<img alt={props.title} src={props.backgroundImg} />}
        onClick={props.onSelect}
      >
        <Flex vertical>
          <Typography.Title className={styles.title} level={4}>
            {props.title}
          </Typography.Title>
          <p className={styles.description}>{props.description}</p>
          {props.extraInfo && (
            <Alert
              className={styles.alert}
              message={props.extraInfo.message}
              type={props.extraInfo.type}
            />
          )}
        </Flex>
      </Card>
    </Badge.Ribbon>
  );
};

export default HomeCheckTypeCard;
