import React from 'react';
import { Card, Col, Flex, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const AnnotationForm = () => {
  const { t } = useTranslation();
  const locizeKey = 'intermediate:pdfGen';
  return (
    <Flex vertical={true} gap={4}>
      <Card title={t(`${locizeKey}:annotation:header`)}>
        <Row gutter={4}>
          <Col span={12}>
            <Form.Item name={['annotation', 'title']} label={t(`${locizeKey}:annotation:title`)}>
              <Input placeholder={t(`${locizeKey}:annotation:titlePlaceholder`)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col span={24}>
            <Form.Item name={['annotation', 'text']} label={t(`${locizeKey}:annotation:text`)}>
              <Input.TextArea placeholder={t(`${locizeKey}:annotation:textPlaceholder`)} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Flex>
  );
};
export default AnnotationForm;
