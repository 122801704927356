import React from 'react';
import { Alert, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/Button/Button';
import HomeCheckTypes from './HomeCheckTypes.enum';

type HomeCheckConfirmSectionProps = {
  isLoading: boolean;
  selectedHomeCheckOption: number;
  shouldShowPaymentAlert: boolean;
  handleConfirm: () => void;
};

const HomeCheckConfirmSection = (props: HomeCheckConfirmSectionProps) => {
  const { t } = useTranslation();

  let btnContent = '';

  switch (props.selectedHomeCheckOption) {
    case HomeCheckTypes.Remote:
      btnContent = t('installerFlow:order:accept:rhc:confirmBtn');
      break;
    case HomeCheckTypes.Onsite:
      btnContent = t('installerFlow:order:accept:hc:confirmBtn');
      break;
    case HomeCheckTypes.None:
      btnContent = t('installerFlow:order:accept:nohc:confirmBtn');
      break;

    default:
      return;
  }

  return (
    <Flex
      justify={props.shouldShowPaymentAlert ? 'space-between' : 'center'}
      align="center"
      gap={16}
    >
      {props.shouldShowPaymentAlert && (
        <Alert type="success" message={t('installerFlow:order:accept:disclaimer')} />
      )}
      <Button type="primary" loading={props.isLoading} onClick={props.handleConfirm}>
        {btnContent}
      </Button>
    </Flex>
  );
};

export default HomeCheckConfirmSection;
