import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const HomeCheckHeaderSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography.Title level={3}>{t('installerFlow:order:accept:mainHeading')}</Typography.Title>
      <Typography.Title level={5}>{t('installerFlow:order:accept:mainIntro')}</Typography.Title>
    </>
  );
};

export default HomeCheckHeaderSection;
