import React from 'react';
import { Modal, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/ui/Button/Button';
import styles from './Accepted.module.scss';

type RemoteHomeCheckModalProps = {
  isOpen: boolean;
  locizeKey: string;
  handleScheduleLater: () => void;
  handleScheduleNow: () => void;
};

const RemoteHomeCheckModal = (props: RemoteHomeCheckModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      className={styles.remoteHomeCheckModal}
      open={props.isOpen}
      title={
        <Typography.Title level={4}>{t(`${props.locizeKey}:rhc:modal:title`)}</Typography.Title>
      }
      onCancel={props.handleScheduleLater}
      closable={false}
      footer={[
        <Button
          key="scheduleLater"
          type="default"
          onClick={props.handleScheduleLater}
          className={styles.scheduleLaterBtn}
        >
          {t(`${props.locizeKey}:rhc:modal:scheduleLater`)}
        </Button>,
        <Button key="scheduleNow" type="primary" onClick={props.handleScheduleNow}>
          {t(`${props.locizeKey}:rhc:modal:scheduleNow`)}
        </Button>,
      ]}
    >
      <p className={styles.description}>
        <Trans i18nKey={`${props.locizeKey}:rhc:modal:description`} />
      </p>
    </Modal>
  );
};

export default RemoteHomeCheckModal;
