import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import { SelectOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Button, TablePaginationConfig } from 'antd';
import { debounce } from 'lodash';
import { FilterValue, SorterResult, SortOrder } from 'antd/lib/table/interface';
import Filters from 'components/ui/Filters/Filters';
import FiltersPanel from 'components/ui/FiltersPanel/FiltersPanel';
import FilterItemContainer from 'components/ui/FilterItemContainer/FilterItemContainer';
import Table from 'components/ui/Table/Table';
import OfferTag from 'components/ui/OfferTag/OfferTag';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import FiltersContext from 'context/filters.context';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';
import { useFilters } from 'hooks/useFilters';
import { clientRequestsFind } from 'store/client-request/client-request.actions';
import { getInstallerPools, installerDataGet } from 'store/installer/installer.actions';
import {
  clientRequestsFindStateSelector,
  foundClientRequestsSelector,
  foundClientRequestsTotalSelector,
} from 'store/client-request/client-request.selectors';
import { RequestState } from 'store/common.types';
import { ClientRequestState, IClientRequest } from 'store/client-request/client-request.types';
import { OfferState } from 'store/offer/offer.types';
import { installerAccessiblePoolsSelector } from 'store/installer/installer.selectors';
import { IOrder, OrderState } from 'store/orders/orders.types';
import { userDataSelector } from 'store/auth/auth.selectors';
import { getUserFullName } from 'utils/helpers';
import { getCurrentOffer } from 'utils/offerHelpers';
import styles from './InstallerClientRequests.module.scss';

type ClientRequestFilters = {
  query: string;
  state: Array<string>;
  poolIds: Array<string>;
};

const defaultFiltersValue: ClientRequestFilters = {
  query: '',
  state: [],
  poolIds: [],
};

type ClientRequestSearchCriteria = {
  limit: number;
  offset: number;
};

const defaultMetaValue: ClientRequestSearchCriteria = {
  limit: DEFAULT_PAGINATION_PAGE_SIZE,
  offset: 0,
};

const getSortingOrderNumber = (sortOrder: SortOrder | undefined) => {
  if (sortOrder) {
    return sortOrder === 'ascend' ? 1 : -1;
  }
  return 0;
};

const InstallerClientRequests = () => {
  const { filters, handleFilterChange } = useFilters(defaultFiltersValue);
  const [meta, setMeta] = useState(defaultMetaValue);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [sortDirections, setSortDirections] = useState<number[]>([]);

  const dispatch = useDispatch();
  const { t } = useTranslation(['clientRequest']);
  const navigate = useNavigate();

  const findState = useSelector(clientRequestsFindStateSelector);
  const foundRequests = useSelector(foundClientRequestsSelector);
  const foundRequestsTotal = useSelector(foundClientRequestsTotalSelector);
  const installerAccessiblePools = useSelector(installerAccessiblePoolsSelector);
  const userData = useSelector(userDataSelector);

  useEffect(() => {
    const config = {
      ...filters,
      limit: DEFAULT_PAGINATION_PAGE_SIZE,
      offset: 0,
    };

    dispatch(clientRequestsFind(config));
    dispatch(installerDataGet());
    dispatch(getInstallerPools());
  }, []); // eslint-disable-line

  const handleSearch = useMemo(
    () =>
      debounce(
        (
          _filters: ClientRequestFilters,
          _meta: ClientRequestSearchCriteria,
          _sortFields: string[],
          _sortDirections: number[],
        ) => {
          dispatch(
            clientRequestsFind({
              ..._filters,
              ..._meta,
              sortFields: _sortFields,
              sortDirections: _sortDirections,
            }),
          );
        },
        300,
      ),
    [], // eslint-disable-line
  );

  useEffect(() => {
    handleSearch(
      filters,
      meta,
      sortFields.filter((item) => item?.length > 0),
      sortDirections.filter((item) => item !== 0),
    );
  }, [filters, sortFields, sortDirections]); // eslint-disable-line

  const onPageChange = (page: number, pageSize: number) => {
    const newMeta = {
      offset: pageSize * (page - 1),
      limit: pageSize,
    };

    setMeta(newMeta);
    dispatch(
      clientRequestsFind({
        ...filters,
        ...newMeta,
        sortFields: sortFields.filter((item) => item?.length > 0),
        sortDirections: sortDirections.filter((item) => item !== 0),
      }),
    );
  };

  const renderFilters = () => {
    const poolOptions = installerAccessiblePools.items
      .map((pool) => ({
        value: pool.id,
        label: pool.name,
      }))
      .sort((item1, item2) => item1.label.localeCompare(item2.label));

    return (
      <FiltersPanel
        query={filters.query}
        onSearchChange={(newValue) => handleFilterChange({ query: newValue })}
        onClear={() => handleFilterChange({ poolIds: [], query: '', state: [] })}
      >
        <FilterItemContainer label={t('intermediate:myProjects:filters:clientRequestState')}>
          <Filters.MultiSelect
            name="state"
            label={t('intermediate:myProjects:filters:searchStatusLabel')}
            options={Object.values(ClientRequestState).map((state) => ({
              value: state,
              label: t(`clientRequest:state:${state}`),
            }))}
            placeholder={t('intermediate:myProjects:filters:searchStatus')}
          />
        </FilterItemContainer>
        <FilterItemContainer label={t('intermediate:myProjects:filters:poolName')}>
          <Filters.MultiSelect
            name="poolIds"
            label={t('intermediate:myProjects:filters:selectPool')}
            options={poolOptions}
            placeholder={t('intermediate:myProjects:filters:searchPoolName')}
          />
        </FilterItemContainer>
      </FiltersPanel>
    );
  };

  const handleRowClick = (tender: any) => {
    const { id } = tender;
    return `/installer/tender/${id}`;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IClientRequest> | SorterResult<IClientRequest>[],
  ) => {
    if (Array.isArray(sorter)) {
      let _sortFields: string[] = [];
      let _sortDirections: number[] = [];
      sorter.forEach((sorterResult) => {
        _sortFields.push(sorterResult.column?.key as string);
        _sortDirections.push(getSortingOrderNumber(sorterResult.order));
      });
      setSortFields(_sortFields);
      setSortDirections(_sortDirections);
    } else {
      setSortFields([sorter.column?.key as string]);
      setSortDirections([getSortingOrderNumber(sorter.order)]);
    }
  };

  const renderNextStep = (clientRequest: IClientRequest) => {
    const btnContent =
      clientRequest.state === ClientRequestState.Done
        ? t('installerFlow:actionWidget:done')
        : clientRequest.state === ClientRequestState.OrderSubmitted
          ? t('installerFlow:dashboard:viewJob')
          : t('installerFlow:dashboard:continueToNextStep');

    return (
      <div className={styles.actionBtnDiv}>
        <Button
          className={clsx(styles.actionBtn, {
            [styles.disabledActionBtn]: clientRequest.state === ClientRequestState.Done,
          })}
          disabled={clientRequest.state === ClientRequestState.Done}
          {...(clientRequest.state !== ClientRequestState.OrderSubmitted && {
            onClick: (e) => {
              e.stopPropagation();
              if (clientRequest.state === ClientRequestState.OrderSubmitted) {
                navigate(`/installer/tender/${clientRequest.id}`);
                return;
              }
              const unconfirmedOrder = clientRequest.orders?.find(
                (order: IOrder) => order.state === OrderState.Created,
              );
              if (unconfirmedOrder) {
                navigate(`/order/${unconfirmedOrder.id}/accept`);
                return;
              }
              const requestOffers = clientRequest.offers?.filter(
                (_offer) => _offer.installerUserId === userData.id,
              );
              const currentOffer = requestOffers && getCurrentOffer(requestOffers);
              if (!currentOffer) return;
              if (currentOffer.state === OfferState.TenderCreated) {
                navigate(`/installer/order/${currentOffer.id}/make-offer`);
                return;
              }
              navigate(`/installer/order/${currentOffer.id}/details`);
            },
          })}
        >
          {btnContent}
        </Button>
      </div>
    );
  };

  const isLoading =
    !(findState === RequestState.Success || findState === RequestState.Error) ||
    installerAccessiblePools.state === RequestState.Loading;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => ({ filters, setFilters: handleFilterChange }), [filters]);

  const CRTableColumns: ColumnsType<IClientRequest> = [
    {
      title: '',
      dataIndex: '',
      key: 'openInNewTab',
      render: (clientRequest) => {
        return (
          <Link
            to={`/installer/tender/${clientRequest.id}`}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <SelectOutlined rotate={90} style={{ fontSize: 22 }} />
          </Link>
        );
      },
    },
    {
      title: 'installerFlow:dashboard:id',
      dataIndex: 'displayId',
      key: 'cr.displayId',
      sorter: { multiple: 1 },
    },
    {
      title: 'installerFlow:dashboard:customer',
      key: 'cr_owner.firstName',
      sorter: { multiple: 1 },
      render: (clientRequest) => {
        return <span>{getUserFullName(clientRequest.owner)}</span>;
      },
    },
    {
      title: 'installerFlow:dashboard:projectPool',
      key: 'cr_pool.name',
      sorter: { multiple: 1 },
      render: (clientRequest) => {
        return <span>{clientRequest.pool?.name}</span>;
      },
    },
    {
      title: 'installerFlow:dashboard:location',
      key: 'cr_owner_address.postalCode',
      sorter: { multiple: 1 },
      render: (clientRequest) => {
        return (
          <span>
            {clientRequest.owner?.address?.postalCode} - {clientRequest.owner?.address?.city}
          </span>
        );
      },
    },
    {
      title: 'installerFlow:dashboard:status',
      dataIndex: 'state',
      key: 'state',
      render: (state: OfferState) => {
        return <OfferTag state={state ?? OfferState.None} />;
      },
    },
    {
      title: 'installerFlow:dashboard:updatedAt',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: Date) => (
        <span>
          <DateFormat date={updatedAt} format={'DD/MM/YYYY'} />
        </span>
      ),
    },
    {
      title: 'installerFlow:dashboard:nextStep',
      dataIndex: '',
      key: 'nextStep',
      align: 'center',
      render: renderNextStep,
    },
  ];

  return (
    <>
      <FiltersContext.Provider value={contextValue}>{renderFilters()}</FiltersContext.Provider>
      <Table
        loading={isLoading}
        columns={CRTableColumns.map((tender: any) => ({ ...tender, title: t(`${tender.title}`) }))}
        dataSource={foundRequests}
        emptyText={t('installerFlow:dashboard:noAvailableOrders')}
        pagination={{
          total: foundRequestsTotal,
          pageSize: meta.limit,
          position: ['bottomRight'],
          current: meta.offset ? meta.offset / meta.limit + 1 : 1,
          onChange: onPageChange,
        }}
        onRowClick={handleRowClick}
        onChange={handleTableChange}
      />
    </>
  );
};

export default InstallerClientRequests;
